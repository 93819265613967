<template>
  <div>
    <x-header :routeIsNews="true">
      <img slot="headerImage" src="../../assets/news/banner.jpg" alt="" />
    </x-header>
    <div class="new_main">
      <div
        class="new_mainItem"
        v-for="(item, index) in this.$store.state.navShow == true ? new_mainItemArr : new_mainItemArrEn"
        :key="index"
        @click="mainItemClick(item.id)"
      >
        <div class="Item_img">
          <img :src="item.thumb" alt="" />
        </div>
        <p class="title">{{ item.title }}</p>
        <p class="intro">
          {{ item.content }}
        </p>
        <p class="date">{{ item.inputtime }}</p>
      </div>

      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="$store.state.navShow == true ? total :totalEn"
          :prev-text="$store.state.navShow == true ? '上一页':'previous'"
          :next-text="$store.state.navShow == true ? '下一页':'next'"
          :current-page="$store.state.navShow == true ? yema : yemaEn"
          @current-change="nowPageFun"
          :page-size="$store.state.navShow == true ?nowTiaoShu :nowTiaoShuEn"
          @prev-click="prevClick"
          @next-click="nextClick"
        >
          <!-- <span slot="total">共页</span> -->
        </el-pagination>
        <p class="total">
          共<span>{{$store.state.navShow == true ? totalPages :totalPagesEn }}</span
          >页
        </p>
        <p class="whatPage" v-if="$store.state.navShow == true">
          到第<input v-model="nowJumpPage" @keyup.enter="inputKeyUpFun" />页
        </p>
         <p class="whatPage" v-else>
          到第<input v-model="nowJumpPageEn" @keyup.enter="inputKeyUpFun" />页
        </p>
        <p class="confirmBtn" @click="inputKeyUpFun">确定</p>
      </div>
    </div>
  </div>
</template>
<script>
// 头导入
import xHeader from '../../components/header.vue'
export default {
  components: {
    xHeader
  },
  data () {
    return {
      // 新闻中心的每一项数据
      new_mainItemArr: [
        {
          newsImg: require('../../assets/news/new2.jpg'),
          newsTitle:
            'MG打造新亮点丨中俄友好、和平与发展委员会MG打造新亮点丨中俄友好、和平与发展委员会',
          newsIntro:
            '本次恳谈会由河北省人民政府外事办公室、河北省人民对外友好协会和邯郸市人民政府共同主办，旨在进一步',
          newDate: '2021-07-13'
        },
        {
          newsImg: require('../../assets/news/new1.jpg'),
          newsTitle: 'MG＋邯郸 | 国际友城恳谈会：MG海外项目资源...',
          newsIntro:
            '本次恳谈会由河北省人民政府外事办公室、河北省人民对外友好协会和邯郸市人民政府共同主办，旨在进一步',
          newDate: '2021-07-13'
        },
        {
          newsImg: require('../../assets/news/new2.jpg'),
          newsTitle: 'MG集团 | 受邀参加跨境电商提速发展之研讨会',
          newsIntro:
            '本次恳谈会由河北省人民政府外事办公室、河北省人民对外友好协会和邯郸市人民政府共同主办，旨在进一步',
          newDate: '2021-07-13'
        }
      ],
      new_mainItemArrEn: [
        {
          newsImg: require('../../assets/news/new2.jpg'),
          newsTitle:
            'MG打造新亮点丨中俄友好、和平与发展委员会MG打造新亮点丨中俄友好、和平与发展委员会',
          newsIntro:
            '本次恳谈会由河北省人民政府外事办公室、河北省人民对外友好协会和邯郸市人民政府共同主办，旨在进一步',
          newDate: '2021-07-13'
        },
        {
          newsImg: require('../../assets/news/new1.jpg'),
          newsTitle: 'MG＋邯郸 | 国际友城恳谈会：MG海外项目资源...',
          newsIntro:
            '本次恳谈会由河北省人民政府外事办公室、河北省人民对外友好协会和邯郸市人民政府共同主办，旨在进一步',
          newDate: '2021-07-13'
        },
        {
          newsImg: require('../../assets/news/new2.jpg'),
          newsTitle: 'MG集团 | 受邀参加跨境电商提速发展之研讨会',
          newsIntro:
            '本次恳谈会由河北省人民政府外事办公室、河北省人民对外友好协会和邯郸市人民政府共同主办，旨在进一步',
          newDate: '2021-07-13'
        }
      ],

      totalPages: 1, // 总页数
      yema: 1, // 页码
      total: 1, // 总条数
      nowTiaoShu: 9, // 条数

      nowJumpPage: 1, // input框跳转的页码

      totalPagesEn: 1, // 总页数
      yemaEn: 1, // 页码
      totalEn: 1, // 总条数
      nowTiaoShuEn: 9, // 条数

      nowJumpPageEn: 1 // input框跳转的页码
    }
  },
  methods: {
    /**
     * input回车 和 确认按钮  事件
     * 肖明霞
     * 2021-11-23
     */
    inputKeyUpFun () {
      if (this.$store.state.navShow === true) {
        if (this.nowJumpPage > 0 && this.nowJumpPage <= this.totalPages) {
          // 调用新闻中心列表
          this.newsListsFun(this.nowJumpPage)
          this.yema = Number(this.nowJumpPage)
          document.documentElement.scrollTop = 0
        } else {
          this.$message({
            showClose: true,
            message: '当前输入得页码不符合',
            type: 'warning'
          })
          this.nowJumpPage = this.totalPages
        }
      } else {
        if (this.nowJumpPageEn > 0 && this.nowJumpPageEn <= this.totalPagesEn) {
          // 调用新闻中心列表
          this.newsListsFunEn(this.nowJumpPageEn)

          this.yemaEn = Number(this.nowJumpPageEn)

          document.documentElement.scrollTop = 0
        } else {
          this.$message({
            showClose: true,
            message: '当前输入得页码不符合',
            type: 'warning'
          })
          this.nowJumpPageEn = this.totalPagesEn
        }
      }
    },
    /**
     * 新闻每一项点击事件
     * 肖明霞
     * 2021-11-21
     */
    mainItemClick (newId) {
      this.$router.push({
        path: '/new_details',
        query: {
          newId
        }
      })
    },

    /**
     * 当前页点击
     * 肖明霞
     * 2021-11-23
     */
    nowPageFun (n) {
      // 调用新闻中心列表
      if (this.$store.state.navShow === true) {
        this.yema = n
        this.newsListsFun(this.yema)
      } else {
        this.yemaEn = n
        this.newsListsFunEn(this.yema)
      }

      document.documentElement.scrollTop = 0
    },

    /**
     * 上一页点击事件
     * 肖明霞
     * 2021-11-23
     */
    prevClick (date) {
      if (this.$store.state.navShow === true) {
        this.yema = date
        this.newsListsFun(this.yema)
      } else {
        this.yemaEn = date
        this.newsListsFunEn(this.yema)
      }

      document.documentElement.scrollTop = 0
    },
    /**
     * 下一页点击事件
     * 肖明霞
     * 2021-11-32
     */
    nextClick (date) {
      if (this.$store.state.navShow === true) {
        this.yema = date
        this.newsListsFun(this.yema)
      } else {
        this.yemaEn = date
        this.newsListsFunEn(this.yema)
      }

      document.documentElement.scrollTop = 0
    },

    /**
     * 新闻列表接口
     * 肖明霞
     * 2021-11-23
     */
    newsListsFun (yemaPage, state = false) {
      this.$axios.get(`/api/Index/newsList?page=${yemaPage}`).then((res) => {
        const { data } = res
        if (data.code === 1) {
          this.total = data.data.count // 总数
          this.new_mainItemArr = data.data.list.map((item) => {
            //   console.log('item=>',item);
            return item
          })

          // 判断是否更新 nowJumpPage
          if (state === true) {
            this.totalPages = Math.ceil(this.total / this.nowTiaoShu)
            this.nowJumpPage = this.totalPages
          }
        } else {
          this.$message({
            showClose: true,
            message: '新闻列表：' + data.msg,
            type: 'error'
          })
        }
      })
    },
    newsListsFunEn (yemaPage, state = false) {
      this.$axios.get(`/apien/Index/newsList?page=${yemaPage}`).then((res) => {
        const { data } = res
        if (data.code === 1) {
          this.total = data.data.count // 总数
          this.new_mainItemArrEn = data.data.list.map((item) => {
            //   console.log('item=>',item);
            return item
          })

          // 判断是否更新 nowJumpPage
          if (state === true) {
            this.totalPagesEn = Math.ceil(this.total / this.nowTiaoShu)
            this.nowJumpPageEn = this.totalPagesEn
          }
        } else {
          this.$message({
            showClose: true,
            message: '新闻列表：' + data.msg,
            type: 'error'
          })
        }
      })
    }
  },
  mounted () {
    this.newsListsFun(undefined, true)
    this.newsListsFunEn(undefined, true)
  }
}
</script>
<style lang="less" scoped>
.new_main {
  width: var(--width1200px);
  // background: orange;
  margin: 102px auto 0;
  display: flex;
  flex-wrap: wrap;
  .new_mainItem {
    width: 373px;
    height: 397px;
    // background: pink;
    font-family: Microsoft YaHei;
    text-align: left;
    border-bottom: 1px solid #ccc;
    margin: 0 0 49px 40px;
    transition: all 0.35s;
    &:nth-child(3n + 1) {
      margin-left: 0px;
    }

    .Item_img {
      width: 373px;
      height: 222px;
      // background: orange;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: bold;
      color: #666666;
      margin-top: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .intro {
      font-size: 14px;
      font-weight: 400;
      color: #888888;
      margin: 20px 0 21px 0;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 超出1行 出现...
      -webkit-box-orient: vertical;
    }
    .date {
      font-size: 14px;
      font-weight: 400;
      color: #cccccc;
    }
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      border-bottom: 1px solid #2e67b1;
      .title,
      .date {
        color: #2e67b1;
      }
    }
  }
  // 分页
  .paging {
    width: 1200px;
    height: 40px;
    // background: orange;
    margin: 11px 0 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    & > p {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
    & .total {
      margin: 0 11px 0 7px;
    }
    // 到第几页
    & .whatPage {
      display: flex;
      align-items: center;
      line-height: 40px;
      border-radius: 3px;
      input {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: rgba(255, 198, 89, 0);
        border: 1px solid #e6e6e6;
        margin: 0 10px;
        text-align: center;
        outline: none;
        font-size: 15px;
        font-weight: 400;
        color: #666;
      }
    }
    // 确定
    & .confirmBtn {
      width: 50px;
      height: 40px;
      background: rgba(255, 198, 89, 0);
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      margin-left: 10px;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        background: #2e67b1;
        color: #fff;
      }
    }
  }
}
</style>
<style>
/* 分页 */
.el-pagination {
  display: flex;
  align-items: center;
}
.btn-prev,
.btn-next {
  width: 70px !important;
  height: 40px !important;
  background: rgba(255, 198, 89, 0) !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 3px !important;
  font-size: 15px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 400 !important;
  color: #666666 !important;
}
/* .el-icon-arrow-left:before{
        content : '上一页'!important;
    }
    .el-icon-arrow-right:before{
        content : '下一页'!important;
    } */
/* 上一页 下一页的修饰 */
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
/* 上一页 下一页伪类 */
/* .el-pagination .btn-prev .el-icon:hover{
        width: 100%;
        height: 100%;
        background: #2E67B1;
        color: #fff!important;
         line-height: 40px;
    }
     .el-pagination .btn-next .el-icon:hover{
        width: 100%;
        height: 100%;
        background: #2E67B1;
        color: #fff!important;
         line-height: 40px;
    }

   */
/* 更多... */
/* .el-icon .more btn-quicknext el-icon-more{
        width: 10px!important;
        height: 2px!important;
        font-size: 15px!important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    } */
/* 中间分页的样式 */
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  width: 40px;
  height: 40px;
  background: rgba(255, 198, 89, 0);
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  line-height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #666;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:hover {
  /* color: #2E67B1!important;; */
  background: #2e67b1;
  color: #fff !important;
  /* color: #fff!important;; */
  line-height: 40px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #2e67b1;
  color: #fff;
}
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  line-height: 37px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  color: #666;
}
</style>
